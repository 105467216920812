// https://Juegalo.com/
export const indexMeta = {
  metaTitle: 'Casino y casa de apuestas online - Juegalo',
  metaDescription: 'En Juegalodisfruta del casino online con mejor cashback y promociones.',
}
// https://Juegalo.com/cl
export const indexClMeta = {
  metaTitle: 'Casino y casa de apuestas online - Juegalo',
  metaDescription:
    'En Juegalodisfruta del casino online con mejores promociones. Apuesta en linea fácil, rapido y seguro con tarjetas.',
}

// https://Juegalo.com/pe
export const indexPeMeta = {
  metaTitle: 'Casino y casa de apuestas online - Juegalo',
  metaDescription:
    'En Juegalodisfruta del casino online con mejor cashback. Apuesta en linea facil, seguro y soporte 24 horas. Retira en soles rapido y con Interbank.',
}

// https://Juegalo.com/cl/casino
export const indexClCasinoMeta = {
  metaTitle: 'Casino y apuestas online - Juegalo',
  metaDescription:
    'Casino online. Apostar y jugar en linea cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, bingo, fruit cocktel.',
}

// https://Juegalo.com/pe/casino
export const indexPeCasinoMeta = {
  metaTitle: 'Casino y apuestas online - Juegalo',
  metaDescription:
    'Casino online. Apostar y jugar en linea bingo, cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, fruit cocktel.',
}

// https://Juegalo.com/cl/liveCasino
export const indexClLiveCasinoMeta = {
  metaTitle: 'Casino en vivo - Juegalo',
  metaDescription:
    'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
}

// https://Juegalo.com/cl/sport
export const indexClSportMeta = {
  metaTitle: 'Apuestas deportivas - Juegalo',
  metaDescription:
    'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol chileno, UEFA Champions League, La Liga, Primera division, Colo colo, Universidad de Chile.',
}

// https://Juegalo.com/pe/sport
export const indexPeSportMeta = {
  metaTitle: 'Apuestas deportivas - Juegalo',
  metaDescription:
    'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol Peru, UEFA Champions League, La Liga, Premier League, Alianza Lima, Sporting, Universitario.',
}

export const indexSeoMeta = {
  index: {
    metaTitle: 'Casino y casa de apuestas online - Juegalo',
    metaDescription: 'En Juegalodisfruta del casino online con mejor cashback y promociones.',
  },
  countrys: {
    cl: {
      index: {
        metaTitle: 'Casino y casa de apuestas online - Juegalo',
        metaDescription:
          'En Juegalodisfruta del casino online con mejores promociones. Apuesta en linea fácil, rapido y seguro con tarjetas.',
      },
      casino: {
        metaTitle: 'Casino y apuestas online - Juegalo',
        metaDescription:
          'Casino online. Apostar y jugar en linea cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, bingo, fruit cocktel.',
      },
      live: {
        metaTitle: 'Casino en vivo - Juegalo',
        metaDescription:
          'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
      },
      sport: {
        metaTitle: 'Apuestas deportivas - Juegalo',
        metaDescription:
          'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol chileno, UEFA Champions League, La Liga, Primera division, Colo colo, Universidad de Chile.',
      },
    },
    pe: {
      index: {
        metaTitle: 'Casino y casa de apuestas online - Juegalo',
        metaDescription:
          'En Juegalodisfruta del casino online con mejor cashback. Apuesta en linea facil, seguro y soporte 24 horas. Retira en soles rapido y con Interbank.',
      },
      casino: {
        metaTitle: 'Casino y apuestas online - Juegalo',
        metaDescription:
          'Casino online. Apostar y jugar en linea bingo, cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, fruit cocktel.',
      },
      live: {
        metaTitle: 'Casino en vivo - Juegalo',
        metaDescription:
          'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
      },
      sport: {
        metaTitle: 'Apuestas deportivas - Juegalo',
        metaDescription:
          'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol, UEFA Champions League, La Liga, Premier League, Alianza Lima, Sporting, Universitario.',
      },
    },
    py: {
      index: {
        metaTitle: 'Casino y casa de apuestas online - Juegalo',
        metaDescription:
          'En Juegalodisfruta del casino online con mejor cashback. Apuesta en linea facil, seguro y soporte 24 horas. Retira en soles rapido y con Interbank.',
      },
      casino: {
        metaTitle: 'Casino y apuestas online - Juegalo',
        metaDescription:
          'Casino online. Apostar y jugar en linea bingo, cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, fruit cocktel.',
      },
      live: {
        metaTitle: 'Casino en vivo - Juegalo',
        metaDescription:
          'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
      },
      sport: {
        metaTitle: 'Apuestas deportivas - Juegalo',
        metaDescription:
          'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol, UEFA Champions League, La Liga, Premier League, Alianza Lima, Sporting, Universitario.',
      },
    },
    ar: {
      index: {
        metaTitle: 'Casino y casa de apuestas online - Juegalo',
        metaDescription:
          'En Juegalo disfruta del casino online con mejor cashback. Apuesta en linea facil, seguro y soporte 24 horas. Retira en soles rapido y con Interbank.',
      },
      casino: {
        metaTitle: 'Casino y apuestas online - Juegalo',
        metaDescription:
          'Casino online. Apostar y jugar en linea bingo, cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, fruit cocktel.',
      },
      live: {
        metaTitle: 'Casino en vivo - Juegalo',
        metaDescription:
          'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
      },
      sport: {
        metaTitle: 'Apuestas deportivas - Juegalo',
        metaDescription:
          'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol, UEFA Champions League, La Liga, Premier League, Alianza Lima, Sporting, Universitario.',
      },
    },
    gt: {
      index: {
        metaTitle: 'Casino y casa de apuestas online - Juegalo',
        metaDescription:
          'En Juegalo disfruta del casino online con mejor cashback. Apuesta en linea facil, seguro y soporte 24 horas. Retira en soles rapido y con Interbank.',
      },
      casino: {
        metaTitle: 'Casino y apuestas online - Juegalo',
        metaDescription:
          'Casino online. Apostar y jugar en linea bingo, cartas, blackjack, poker, torneo, tragamonedas, slot, ruleta, fruit cocktel.',
      },
      live: {
        metaTitle: 'Casino en vivo - Juegalo',
        metaDescription:
          'Juega y apuesta poker, blackjack, ruleta, bingo desde cualquier lugar, como si estuvieras en un casino real.',
      },
      sport: {
        metaTitle: 'Apuestas deportivas - Juegalo',
        metaDescription:
          'Apuesta a tu equipo favorito, para multiples eventos deportivos. Partidos de futbol, UEFA Champions League, La Liga, Premier League, Alianza Lima, Sporting, Universitario.',
      },
    },
  },
}
