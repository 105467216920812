'use client';

import type { LottiePlayer } from 'lottie-web';
import { useEffect, useRef, useState } from 'react';

interface Animation {
  pathAnimation: string;
  className?: string;
}

export const Animation = ({ pathAnimation, className }: Animation) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: pathAnimation,
      });
      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center', 
      width: '100%',
    }}>
      <div style={{ width: '400px' }} ref={ref} />
    </div>
  );
};

interface IlistAnimation {
  loadBetnroll: string;
  loadJuegalo: string;
  loadingBonoCashback: string;
  loadingMoneyTransfer: string;
  serpentina: string;
  notFound: string;
  successCash: string;
  loadingResponse: string;
  paymentFail: string;
  loadingSuccess: string;
  coinJuegalo: string;
  monedaJuegalo: string;
  monedaRegisterJuegalo: string;
}

export const listAnimation: IlistAnimation = {
  loadBetnroll: '/lotties/loadBetnroll.json',
  loadJuegalo: '/lotties/loadJuegalo.json',
  loadingBonoCashback: '/lotties/loadingBonoCashback.json',
  loadingMoneyTransfer: '/lotties/money-transfer.json',
  serpentina: '/lotties/serpentina.json',
  notFound: '/lotties/not_found.json',
  successCash: '/lotties/success_cash.json',
  loadingResponse: '/lotties/loading_1.json',
  paymentFail: '/lotties/paymentfail.json',
  loadingSuccess: '/lotties/success_1.json',
  coinJuegalo: '/lotties/coin_juegalo.json',
  monedaJuegalo: '/lotties/moneda_figma.json',
  monedaRegisterJuegalo: '/lotties/Moneda_1_final.json',
};
