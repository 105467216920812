import Seo from '@components/Template/Seo';
import { Animation, listAnimation } from '@hooks/useAnimationLottie';
import { inviteResetErrors, verifyCookie } from '@clean/application/redux/auth/actions/auth';
import { UpdatedDataLayoutRequest } from '@clean/application/redux/layout/actions/layout';
import { indexMeta } from '@seo/index';
import axios from 'axios';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import nextI18NextConfig from '../next-i18next.config.js';

function Home({
  inviteResetErrors,
  UpdatedDataLayoutRequest,
  errorsInvite,
  loadLayout,
  countryDetected,
  browserNotAllowed,
  loadingCountryDetected
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loadLayout && !browserNotAllowed) {
      router.push(`/${countryDetected}`);
    }
  }, [loadLayout, browserNotAllowed, countryDetected]);

  const router = useRouter();

  const {
    formState: { errors },
  } = useForm();

  useEffect(() => {
    UpdatedDataLayoutRequest();
  }, []);

  const validCookie = async () => {
    const { data: res } = await axios.get('/api/v2/Cookie/validCookie');
    const haveJuegaloCookie = res.cookie;
    if (!haveJuegaloCookie) {
      dispatch(verifyCookie());
    }
  };

  useEffect(() => {
    validCookie()
  }, []);

  useEffect(() => {
    if (errorsInvite?.length > 0) {
      Swal.fire({
        title: 'Error',
        text: errorsInvite[0].message,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        timer: 4000,
      });
      inviteResetErrors();
    }
  }, [errorsInvite]);

  useEffect(() => {
    if (browserNotAllowed) {
      router.push('/notAllowed');
    }
  }, [browserNotAllowed]);

  if (browserNotAllowed && router.pathname !== '/notAllowed') {
    return (
      <>
        <Seo
          title={indexMeta.metaTitle}
          description={indexMeta.metaDescription}
          canonical={
            process.env.NEXT_PUBLIC_URL_ENVIROMENT +
            useRouter().asPath.split('#')[0]
          }
          hreflang={{
            hreflang: `${useRouter().locale}-${countryDetected}`,
            href:
              process.env.NEXT_PUBLIC_URL_ENVIROMENT +
              useRouter().asPath.split('#')[0],
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Animation pathAnimation={listAnimation.loadJuegalo} />
        </div>
      </>
    );
  }

  return (
    <>
      <Seo
        title={indexMeta.metaTitle}
        description={indexMeta.metaDescription}
        canonical={
          process.env.NEXT_PUBLIC_URL_ENVIROMENT +
          useRouter().asPath.split('#')[0]
        }
        hreflang={{
          hreflang: `${useRouter().locale}-${countryDetected}`,
          href:
            process.env.NEXT_PUBLIC_URL_ENVIROMENT +
            useRouter().asPath.split('#')[0],
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Animation pathAnimation={listAnimation.loadJuegalo} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isInvite: state.auth.isInvite,
  loadingInvite: state.auth.loadingInvite,
  errorsInvite: state.auth.errorsInvite,
  countryDetected: state.fp.countryDetected,
  onlyWithInvitation: state.layout.onlyWithInvitation,
  loadLayout: state.layout.loadLayout,
  browserNotAllowed: state.fp.browserNotAllowed,
  loadingCountryDetected: state.fp.loadingCountryDetected
});

const mapDispatchToProps = (dispatch) => {
  return {
    inviteResetErrors: bindActionCreators(inviteResetErrors, dispatch),
    UpdatedDataLayoutRequest: bindActionCreators(
      UpdatedDataLayoutRequest,
      dispatch
    ),
  };
};

export async function getStaticProps({ locale, params }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], nextI18NextConfig)),
    },
    revalidate: 300,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
